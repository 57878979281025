import React, { useState, useLayoutEffect, useRef } from 'react';

import {
  Button,
  Container,
  Form,
  Icon,
  Grid,
  Message,
  Loader,
  Transition,
} from 'semantic-ui-react';

import { useAuth } from '../../hooks/useAuth';
import { Link, useNavigate } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

const Login = () => {
  const auth = useAuth();
  const { signin, user, error, sendPasswordResetEmail } = auth;
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetSent, setResetSent] = useState(false);

  const firstUpdate = useRef(true);

  // have to use useEffect because error is set async
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          toast.error('Invalid email');
          return;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          toast.error('Invalid email and password combination');
          return;
        default:
          toast.error('Unknown error');
      }
    } else if (!user) {
      toast.error('Unexpected error, please try again.');
    }
  }, [auth.error, error, user]);

  const handleSubmit = async () => {
    try {
      const user = await signin(email, password);
      if (user) {
        navigate('/dashboard', {
          state: { successfulLogin: true },
        });
      }
    } catch (err) {
      toast.error('Unexpected error, please try again.');
    }
  };

  const handleReset = async () => {
    try {
      const result = await sendPasswordResetEmail(email);
      if (result) {
        setResetSent(true);
      } else {
        toast.error(error);
      }
    } catch (err) {
      toast.error('There was a problem sending the password reset email.');
    }
  };

  return auth ? (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Container style={{ marginTop: '10%' }}>
            <Transition visible={resetSent} animation="scale" duration={500}>
              <Message positive>
                <Message.Header>Password Reset Email Sent</Message.Header>
                <Message.Content>
                  Please check your inbox to complete the process.{' '}
                  <button className="link-button">Resend Email</button>
                </Message.Content>
              </Message>
            </Transition>
            <Message attached header="Login" />
            <Form className="attached fluid segment padded">
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button onClick={handleSubmit} color="teal">
                Login
              </Button>
            </Form>
            <Message attached="bottom" warning>
              <Icon name="help" />
              Don't have an account?{' '}
              <Link to="/register-firebase">Register here</Link> instead. Forgot
              your password?{' '}
              <button className="link-button" onClick={() => handleReset()}>
                Reset Password
              </button>
            </Message>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <Loader active="true" content="Loading" />
  );
};

export default Login;
