const firebaseConfig = {
  apiKey: 'AIzaSyAIZwuqnyuQ_sDsMy-DnPAWStdXfGGwY2k',
  authDomain: 'heatwav-d4aff.firebaseapp.com',
  databaseURL: 'https://heatwav-d4aff.firebaseio.com',
  projectId: 'heatwav-d4aff',
  storageBucket: 'heatwav-d4aff.appspot.com',
  messagingSenderId: '245725258028',
  appId: '1:245725258028:web:5dbe3991a7f271a53dcc89',
  measurementId: 'G-X7FB3BR8E0',
};

export default firebaseConfig;
