import React, { useState, useEffect } from 'react';
import { Alert, Modal, Button, Form, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import prependHttp from 'prepend-http';

import {
  faPencilAlt,
  faMapMarkerAlt,
  faHome,
  faShareAlt,
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';

import getArtistType from '../../../util/getArtistType';
import LoadingButton from '../../../components/LoadingButton';

import '../modal.css';
import './EditArtistModal.css';

const validateWander = (n) => !isNaN(n) && n >= 0;
const validateLat = (lat) => !isNaN(lat) && Math.abs(lat) <= 90;
const validateLong = (lng) => !isNaN(lng) && Math.abs(lng) <= 180;

const generateGeoJSONPoint = (coords) => ({
  type: 'Point',
  coordinates: coords,
});

function EditArtistModal({
  row,
  handleClose,
  handleEdit,
  handleDelete,
  handleSync,
  isAdmin,
}) {
  const { original, values } = row;
  const { location, base } = values;
  const type = getArtistType(original.users, original.simulate);
  const [locationAvailable, setLocationAvailable] = useState(location != null);
  const [currentCoords, setCurrentCoords] = useState(
    locationAvailable ? (location ? location.coordinates : [0, 0]) : [0, 0]
  );
  const [baseCoords, setBaseCoords] = useState(base.coordinates || [0, 0]);
  const [wander, setWander] = useState(values.wander || 0);
  const [dynamicMovement, setDynamicMovement] = useState(
    values.dynamicMovement
  );
  const [socials, setSocials] = useState(original.externalUrls || {});
  const [newSocial, setNewSocial] = useState(['', '']);

  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const fieldErrors = {
    long: !validateLong(currentCoords[0]),
    lat: !validateLat(currentCoords[1]),
    baseLong: !validateLong(baseCoords[0]),
    baseLat: !validateLat(baseCoords[1]),
    wander: !validateWander(wander),
  };

  const canEdit = isAdmin || type === 'Virtual';

  useEffect(() => {
    fieldErrors.long = !validateLong(currentCoords[0]);
    fieldErrors.lat = !validateLat(currentCoords[1]);
    fieldErrors.baseLong = !validateLong(baseCoords[0]);
    fieldErrors.baseLat = !validateLat(baseCoords[1]);
    fieldErrors.wander = !validateWander(wander);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCoords, baseCoords, wander]);

  const handleSubmit = async () => {
    if (!edited) {
      handleClose();
      return;
    }
    setLoading(true);
    const patch = [
      { op: 'replace', path: '/base/coordinates', value: baseCoords },
      { op: 'replace', path: '/wander', value: wander },
      { op: 'replace', path: '/dynamicMovement', value: dynamicMovement },
      {
        op: original.hasOwnProperty('externalUrls') ? 'replace' : 'add',
        path: '/externalUrls',
        value: socials,
      },
      ...(locationAvailable
        ? values.location
          ? [
              {
                op: 'replace',
                path: '/location/coordinates',
                value: currentCoords,
              },
            ]
          : [
              {
                op: 'replace',
                path: '/location',
                value: generateGeoJSONPoint(currentCoords),
              },
            ]
        : [{ op: 'replace', path: '/location', value: null }]),
    ];
    const res = await handleEdit(original._id, patch);
    setLoading(false);

    if (res.status === 404) {
      handleClose();
      // the artist probably got deleted by amother mod during editing
      toast.warn('Artist no longer exists. Please try refreshing.');
    } else if (res.status === 403) {
      // the user might no longer be logged in
      toast.error('Invalid credentials. Please try to login again.');
    } else if (res.status === 409) {
      // duplicate key error
      toast.error('Duplicate key error. Please try again.');
    } else if (res.status === 422) {
      // display validation error
      toast.error(
        'Validation failed. Please make sure all fields are correct.'
      );
      // TODO: Make this more detailed
    } else if (res.status !== 200) {
      // display unexpected error
      toast.error('Unexpected error. Please try again.');
    } else {
      // close modal and display success
      handleClose();
      toast.success('Edits saved.', { autoClose: 1500 });
    }
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    const res = await handleDelete(original._id);
    setLoading(false);
    if (res.status === 404) {
      handleClose();
      // the artist probably got deleted by amother mod during editing
      toast.warn('Artist no longer exists. Please try refreshing.');
    } else if (res.status === 403 || res.status === 401) {
      // the user might no longer be logged in
      toast.error('Invalid credentials. Please relog and try again.');
    } else if (res.status !== 200) {
      toast.error('Unexpected error. Please try again.');
    } else {
      handleClose();
      toast.success('Artist deleted.');
    }
  };

  const handleSocialsDelete = (key) => {
    const { [key]: _, ...rest } = socials;
    setSocials(rest);
    setEdited(true);
  };

  const handleSocialsUpdate = (key, value) => {
    setSocials({ ...socials, [key]: value });
    setEdited(true);
  };

  const renderModalBody = () =>
    !deleteMode ? (
      <>
        {!canEdit && (
          <Alert variant="warning">
            <Alert.Heading>
              Warning, you don't have permission to edit this artist.
            </Alert.Heading>
            <p>
              This is not a virtual artist. Please contact an admin for further
              assistance.
            </p>
          </Alert>
        )}
        <div className="coord-label">
          Current Coordinates{' '}
          <FontAwesomeIcon className="coord-icon" icon={faMapMarkerAlt} />
        </div>
        <Form.Row className="coord-row">
          <Form.Group as={Col} controlId="currentLong">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type="number"
              placeholder="Current Longitude"
              isInvalid={fieldErrors.long}
              isValid={!fieldErrors.long}
              value={currentCoords[0]}
              onChange={(e) => {
                setCurrentCoords([e.target.value, currentCoords[1]]);
                setEdited(true);
              }}
              disabled={!canEdit || !locationAvailable}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="currentLat">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type="number"
              placeholder="Current Latitude"
              isInvalid={fieldErrors.lat}
              isValid={!fieldErrors.lat}
              value={currentCoords[1]}
              onChange={(e) => {
                setCurrentCoords([currentCoords[0], e.target.value]);
                setEdited(true);
              }}
              disabled={!canEdit || !locationAvailable}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col xs="auto">
            <Form.Check
              checked={locationAvailable}
              type="checkbox"
              id="dynamic-available"
              label="Location Available"
              onChange={(e) => {
                setLocationAvailable(e.target.checked);
                setEdited(true);
              }}
              disabled={!canEdit}
            />
          </Col>
        </Form.Row>
        <div className="coord-label">
          Base Coordinates{' '}
          <FontAwesomeIcon className="coord-icon" icon={faHome} />
        </div>
        <Form.Row className="coord-row">
          <Form.Group as={Col} controlId="baseLong">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type="number"
              placeholder="Base Longitude"
              isInvalid={fieldErrors.baseLong}
              isValid={!fieldErrors.baseLong}
              value={baseCoords[0]}
              onChange={(e) => {
                setBaseCoords([e.target.value, baseCoords[1]]);
                setEdited(true);
              }}
              disabled={!canEdit}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="baseLat">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type="number"
              placeholder="Base Latitude"
              isInvalid={fieldErrors.baseLat}
              isValid={!fieldErrors.baseLat}
              value={baseCoords[1]}
              onChange={(e) => {
                setBaseCoords([baseCoords[0], e.target.value]);
                setEdited(true);
              }}
              disabled={!canEdit}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col xs="auto">
            <Form.Group controlId="wander">
              <Form.Label>Wander (miles)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Wander"
                isInvalid={fieldErrors.wander}
                isValid={!fieldErrors.wander}
                value={wander}
                onChange={(e) => {
                  setWander(e.target.value);
                  setEdited(true);
                }}
                disabled={!canEdit}
              />
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Check
              checked={dynamicMovement}
              type="checkbox"
              id="dynamic-movement"
              label="Dynamic Movement"
              className="dynamic-movement-checkbox"
              onChange={(e) => {
                setDynamicMovement(e.target.checked);
                setEdited(true);
              }}
              disabled={!canEdit}
            />
          </Col>
        </Form.Row>
        <div className="coord-label">
          Social Links{' '}
          <FontAwesomeIcon className="coord-icon" icon={faShareAlt} />
        </div>
        {Object.entries(socials).map(([key, value]) => (
          <div className="artist-social-row" key={key}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{key}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={value}
                onChange={(e) => handleSocialsUpdate(key, e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-danger"
                  onClick={() => handleSocialsDelete(key)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        ))}

        <InputGroup>
          <InputGroup.Prepend>
            <Form.Control
              as="select"
              id="socialSelect"
              style={{ padding: '.2em', margin: 0 }}
              value={newSocial[0]}
              isValid={newSocial[0] !== ''}
              onChange={(e) => {
                setNewSocial([e.target.value, newSocial[1]]);
              }}
            >
              <option value="">Choose...</option>
              <option value="spotify">Spotify</option>
              <option value="instagram">Instagram</option>
              <option value="youtube">Youtube</option>
              <option value="soundcloud">Soundcloud</option>
              <option value="twitter">Twitter</option>
              <option value="other">Other</option>
            </Form.Control>
          </InputGroup.Prepend>

          <Form.Control
            type="text"
            placeholder="https://socialwebsite.com"
            isValid={newSocial[1] !== ''}
            value={newSocial[1]}
            onChange={(e) => {
              setNewSocial([newSocial[0], e.target.value]);
            }}
          />

          <InputGroup.Append>
            <Button
              variant="outline-success"
              disabled={newSocial[0] === '' || newSocial[1] === ''}
              onClick={() => {
                const [key, value] = newSocial;
                console.log(key, value);
                setSocials({ ...socials, [key]: prependHttp(value) });
                setNewSocial(['', '']);
                setEdited(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </>
    ) : (
      <>
        <Alert variant={type === 'Virtual' ? 'warning' : 'danger'}>
          <Alert.Heading>
            {`Attempting to delete ${type.toLowerCase()} artist`}
          </Alert.Heading>
          <p>
            {type !== 'Virtual'
              ? 'WARNING! This artist is connected to a real account.'
              : ''}
            {canEdit
              ? 'Are you sure you want to delete this artist? This action cannot be undone.'
              : "Sorry, you don't have permission to delete this artist."}
          </p>
        </Alert>
      </>
    );

  const renderModalFooter = () =>
    !deleteMode ? (
      <>
        <div className="mr-auto">
          <Button
            variant="danger"
            onClick={() => setDeleteMode(true)}
            disabled={!canEdit}
          >
            Delete Artist
          </Button>{' '}
          <Button
            onClick={() => {
              handleSync(original._id, original.name);
            }}
            variant="info"
          >
            Sync with Spotify
          </Button>
        </div>

        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          variant="orange"
          onClick={handleSubmit}
          disabled={!canEdit || Object.values(fieldErrors).includes(true)}
          loading={loading}
        >
          Save Changes
        </LoadingButton>
      </>
    ) : (
      <>
        <Button variant="secondary" onClick={() => setDeleteMode(false)}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => handleConfirmDelete()}
          disabled={!canEdit}
        >
          Confirm Delete
        </Button>
      </>
    );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <span className="edit-artist-header">{values.name}</span>
          <span className={`edited edit-${edited ? 'true' : 'false'}`}>
            <FontAwesomeIcon className="edited-icon" icon={faPencilAlt} />
            <span className="edited-text">Edited</span>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody()}</Modal.Body>
      <Modal.Footer>{renderModalFooter()}</Modal.Footer>
    </>
  );
}

export default EditArtistModal;
