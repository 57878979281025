import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Form,
  Grid,
  Message,
  Icon,
  Transition,
  Loader,
} from 'semantic-ui-react';
import { Link } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RegistrationSteps from '../../components/RegistrationSteps';

import isEmail from 'validator/es/lib/isEmail';
import passwordValidator from 'password-validator';

import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from '@reach/router';

const passwordRequirements = [
  'At least one uppercase letter',
  'At least one lowercase letter',
  'At least six characters long',
  'At least one numerical digit',
];

const FirebaseRegistration = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const schema = new passwordValidator();
  schema.min(6).has().uppercase().has().lowercase().has().digits();

  const fieldValidationMap = {
    email: isEmail,
    password: (v) => schema.validate(v),
  };

  const [fieldValues, setFieldValues] = useState({
    email: '',
    password: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    email: null,
    password: null,
  });

  const [fieldFocus, setFieldFocus] = useState({
    email: false,
    password: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const [formError, setFormError] = useState(null);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [pageError, setPageError] = useState(null);

  const getIcon = (name) => {
    return fieldErrors[name] === null && fieldValues[name] !== ''
      ? 'check'
      : null;
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    // update the value
    setFieldValues({ ...fieldValues, [name]: value });

    // check for empty input
    if (value === '') {
      setFieldErrors({
        ...fieldErrors,
        [name]: {
          content: 'Please enter your ' + name,
          pointing: 'below',
        },
      });
      return;
    }

    // check that input is valid
    if (!fieldValidationMap[name](value)) {
      setFieldErrors({
        ...fieldErrors,
        [name]: {
          content: 'Please enter a valid ' + name,
          pointing: 'below',
        },
      });
      return;
    }

    // there were no errors found
    setFieldErrors({
      ...fieldErrors,
      [name]: null,
    });
    setPageError(null);
  };

  const handleSubmit = async () => {
    console.log(fieldValues);
    const { email, password } = fieldValues;
    if (!fieldValidationMap.email(email)) {
      toast.error('Please make sure email is valid.');
      return;
    }
    if (!fieldValidationMap.password(password)) {
      toast.error('Please make sure password is valid.');
      return;
    }
    try {
      // create the firebase account
      const user = await auth.signup(email, password);

      // if success show a message and redirect to next stage
      if (!user) {
        if (auth.error) {
          switch (auth.error.code) {
            case 'auth/email-already-in-use':
              toast.error('Email already in use');
              return;
            case 'auth/invalid-email':
              toast.error('Invalid email');
              return;
            case 'auth/weak-password':
              toast.error('Password too weak');
              return;
            default:
              toast.error('Unknown error');
          }
        }
        toast.error('Unexpected error creating account. Please try again.');
        return;
      }
      navigate('/register-mod', {
        state: { createdFirebaseAccount: true },
      });
    } catch (err) {
      // if fails prompt the user to try again and show form error
      toast.error('Unexpected error creating account. Please try again.');
      console.log(err);
    }
  };

  const handleFocus = ({ target }) => {
    const { name } = target;
    setFieldFocus({
      ...fieldFocus,
      [name]: true,
    });
  };

  const handleBlur = ({ target }) => {
    const { name } = target;
    setFieldFocus({
      ...fieldFocus,
      [name]: false,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // disable the submit button if there are any errors or empty (first time)
    if (
      fieldErrors.password !== null ||
      fieldErrors.email !== null ||
      fieldValues.password === '' ||
      fieldValues.email === ''
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [
    fieldErrors.password,
    fieldErrors.email,
    fieldValues.password,
    fieldValues.email,
  ]);

  const renderFormTips = () => {
    const { password, email } = fieldFocus;
    return (
      <Transition
        visible={password || email}
        animation="fade up"
        duration={500}
      >
        <Message
          header={
            password
              ? 'Password Requirements'
              : email
              ? 'Email Requirements'
              : 'Requirements'
          }
          list={password ? passwordRequirements : null}
          content={
            email
              ? 'Please make sure your email takes the form of: xxx@xxx.xxx'
              : null
          }
          info
        />
      </Transition>
    );
  };

  return auth ? (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Container style={{ marginTop: '10%' }}>
            <RegistrationSteps
              firebaseState={{ active: true }}
              modState={{ disabled: true }}
              verifyState={{ disabled: true }}
            />
            <Message
              attached
              header="Registration"
              content="Fill out the form below to sign-up for a new account"
            />
            <Form
              className="attached fluid segment padded"
              onSubmit={handleSubmit}
            >
              <Form.Field>
                <Form.Input
                  name="email"
                  label="Email"
                  error={fieldErrors.email}
                  placeholder="example@email.com"
                  value={fieldValues.email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  error={fieldErrors.password}
                  placeholder="********"
                  value={fieldValues.password}
                  icon={
                    <Icon
                      name={showPassword ? 'eye slash' : 'eye'}
                      link
                      onClick={handleTogglePassword}
                    />
                  }
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </Form.Field>
              <Button color="teal" disabled={submitDisabled} type="submit">
                Next
              </Button>
            </Form>
            <Message attached="bottom" warning>
              <Icon name="help" />
              Already signed up? <Link to="/login">Login here</Link> instead.
            </Message>
            {renderFormTips()}
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <Loader active="true" content="Loading" />
  );
};

export default FirebaseRegistration;
