import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Form,
  Grid,
  Message,
  Transition,
  Loader,
  Icon,
} from 'semantic-ui-react';
import { Link } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from '@reach/router';

import RegistrationSteps from '../../components/RegistrationSteps';

import heatwav from '../../apis/heatwav';
import { useAuth } from '../../hooks/useAuth';

const fieldNameMap = {
  screenName: 'screen name',
  authCode: 'auth code',
};

const fieldValidationMap = {
  screenName: async (value) =>
    await heatwav.get('/moderators', { params: { screenName: value } }),
  authCode: async (value) =>
    await heatwav.get('/whitelist-codes/auth-code/' + value),
};

const ModRegistration = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [fieldValues, setFieldValues] = useState({
    screenName: '',
    authCode: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    screenName: null,
    authCode: null,
  });

  const [fieldLoadings, setFieldLoadings] = useState({
    screenName: false,
    authCode: false,
  });

  const [fieldFocus, setFieldFocus] = useState({
    screenName: false,
    authCode: false,
  });

  const [lastFocused, setLastFocused] = useState({
    screenName: false,
    authCode: false,
  });

  const [formError, setFormError] = useState(null);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [pageError, setPageError] = useState(null);

  const getIcon = (name) => {
    return fieldErrors[name] === null && fieldValues[name] !== ''
      ? 'check'
      : null;
  };

  const handleChange = async ({ target }) => {
    const { name, value } = target;
    // update the value
    setFieldValues({ ...fieldValues, [name]: value });

    // check for empty
    if (value === '') {
      setFieldErrors({
        ...fieldErrors,
        [name]: {
          content: 'Please enter your ' + fieldNameMap[name],
          pointing: 'below',
        },
      });
      return;
    }

    // set loading state to true
    setFieldLoadings({ ...fieldLoadings, [name]: true });
    // check for errors
    try {
      const response = await fieldValidationMap[name](value);
      if (name === 'screenName') {
        // return an error if there an existing user has the screen name or email
        if (response.status === 200 && response.data.data.count > 0) {
          setFieldErrors({
            ...fieldErrors,
            [name]: {
              content: `A moderator with this ${fieldNameMap[name]} already exists. Please login or make sure everything is spelled correctly`,
              pointing: 'below',
            },
          });
          return;
        }
      } else {
        // return an error if the auth code does not exist
        if (response.status === 404) {
          setFieldErrors({
            ...fieldErrors,
            [name]: {
              content: `This auth code is invalid at this time. Please make sure it is entered correctly`,
              pointing: 'below',
            },
          });
          return;
        }
      }
      // there were no errors found clear fleld and page errors
      setFieldErrors({
        ...fieldErrors,
        [name]: null,
      });
      setPageError(null);
    } catch (err) {
      // display an small message if encountering an unexpected validation error
      setPageError(err.message);
    } finally {
      // set loading state to false
      setFieldLoadings({ ...fieldLoadings, [name]: false });
    }
  };

  const handleSubmit = async () => {
    console.log(fieldValues);
    const { user } = auth;
    console.log(user);

    // sanity check
    if (!user || !user.email) {
      toast.error('Unexpected error, please make sure you are signed in.');
      return;
    }

    try {
      const token = await user.getIdToken();
      const { screenName, authCode } = fieldValues;
      console.log(screenName, authCode);

      // send POST request to backend
      const res = await heatwav.post(
        '/moderators',
        {
          screenName,
          authCode,
          email: user.email,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );

      console.log('test');

      if (res.status === 403) {
        toast.error('Auth code is invalid. Please try again.');
        return;
      }
      if (res.status === 409) {
        toast.error('Screen name already taken. Please try again.');
        return;
      }
      if (res.status !== 201) {
        toast.error('Unexpected error creating account. Please try again.');
        return;
      }

      navigate('/verify', {
        state: { createdModAccount: true },
      });
    } catch (err) {
      console.log(err);
      toast.error('Unexpected error creating account. Please try again.');
      return;
    }
  };

  const handleFocus = ({ target }) => {
    const { name } = target;
    setFieldFocus({
      ...fieldFocus,
      [name]: true,
    });
    const lastFocusedNew = {};
    for (const key of Object.keys(lastFocused)) {
      lastFocusedNew.key = false;
    }
    setLastFocused({
      ...lastFocusedNew,
      [name]: true,
    });
  };

  const handleBlur = ({ target }) => {
    const { name } = target;
    setFieldFocus({
      ...fieldFocus,
      [name]: false,
    });
  };

  // TODO: abstract this into a custom hook
  // redirect to dashboard if already logged in
  useEffect(() => {
    if (auth.moderator) {
      navigate('/dashboard');
    }
  }, [auth, navigate]);

  useEffect(() => {
    // disable the submit button if there are any errors or empty (first time)
    if (
      fieldErrors.screenName !== null ||
      fieldErrors.authCode !== null ||
      fieldValues.screenName === '' ||
      fieldValues.authCode === ''
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [
    fieldErrors.screenName,
    fieldErrors.authCode,
    fieldValues.screenName,
    fieldValues.authCode,
  ]); // only check if input changed

  // display toast for successful firebase registration
  useEffect(() => {
    if (props.location.state && props.location.state.createdFirebaseAccount) {
      toast.success('Saved email and password!');
    }
  }, [props.location.state]);

  const renderFormTips = () => {
    return (
      <Transition
        visible={fieldFocus.screenName || fieldFocus.authCode}
        animation="fade up"
        duration={500}
      >
        <Message
          header={
            lastFocused.screenName
              ? 'Screen Name Requirements'
              : 'Auth Code Requirements'
          }
          content={
            lastFocused.screenName
              ? 'Please choose an unique screen name'
              : 'Please enter the auth code you recieved in your invitation'
          }
          info
        />
      </Transition>
    );
  };

  // if loading display a loading page

  return auth ? (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Container style={{ marginTop: '10%' }}>
            <RegistrationSteps
              firebaseState={{ completed: true }}
              modState={{ active: true }}
              verifyState={{ disabled: true }}
            />
            <Message
              attached
              header="Registration"
              content="Fill out the form below to complete your sign-up"
            />
            <Form
              className="attached fluid segment padded"
              onSubmit={handleSubmit}
            >
              <Form.Field>
                <Form.Input
                  name="screenName"
                  label="Screen Name"
                  error={fieldErrors.screenName}
                  placeholder="user123"
                  value={fieldValues.screenName}
                  loading={fieldLoadings.screenName}
                  icon={getIcon('screenName')}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="authCode"
                  label="Auth Code"
                  error={fieldErrors.authCode}
                  placeholder="V1StGXR8_Z5jdHi6B-myT"
                  value={fieldValues.authCode}
                  icon={getIcon('authCode')}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </Form.Field>
              <Button color="teal" disabled={submitDisabled} type="submit">
                Submit
              </Button>
            </Form>
            <Message attached="bottom" warning>
              <Icon name="help" />
              Already signed up? <Link to="/login">Login here</Link> instead.
            </Message>
            {renderFormTips()}
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <Loader active="true" content="Loading" />
  );
};

export default ModRegistration;
