import React from 'react';
import logo from '../../assets/heatwav.png';
import './Sidebar.css';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserFriends,
  faUsersCog,
  faMapMarkedAlt,
} from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import getEnvironment from '../../util/getEnvironment';
const environment = getEnvironment();

const SideBarItem = ({ link, text, icon }) => (
  <Link to={link} className="sidebar-link">
    <FontAwesomeIcon className="sidebar-icon" icon={icon} />
    <p className="sidebar-link-text">{text}</p>
  </Link>
);

const SideBar = () => (
  <div className="sidebar">
    <div className="cluster">
      <img className="sidebar-logo" src={logo} alt="sidebar-logo" />
      <h2>ADMIN</h2>
      <h5>
        <Badge bg={environment.theme}>{environment.text.toUpperCase()}</Badge>
      </h5>
    </div>
    <SideBarItem link="/requests" icon={faUsersCog} text="Artist Requests" />
    <SideBarItem link="/artists" icon={faUserFriends} text="Artists" />
    <SideBarItem link="/dashboard" icon={faMapMarkedAlt} text="Heat Map" />
    {/* <Link className="sidebar-link">Account Management</Link> */}
  </div>
);

export default SideBar;
