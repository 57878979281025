import React from 'react';
import './Dashboard.css';
import Sidebar from '../../components/Sidebar';
import Map from '../../components/Map';
import { useRequireAuth } from '../../hooks/useRequireAuth';

const Dashboard = () => {
  const _ = useRequireAuth({});
  return (
    <div>
      <Sidebar />
      <div className="dashboard">
        <main>
          <Map />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
