import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { useAuth } from '../../hooks/useAuth';
import Table from './Table';

function ModInvites(props) {
  // for the modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const auth = useAuth();

  const columns = useMemo(
    () => [
      {
        Header: 'Auth Code',
        accessor: 'authCode',
      },
      {
        Header: 'Note',
        accessor: 'note',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
      },
      {
        Header: 'Revoke',
        accessor: 'revoke',
        cell: ({ row }) => <Button>Delete</Button>,
      },
    ],
    []
  );

  return <div>Moderator Invites</div>;
}

export default ModInvites;
