import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.css';

function Loader({ active, content, animation, variant }) {
  return active ? (
    <div className="loader-container">
      <Spinner animation={animation} variant={variant} size="lg" />
      {content}
    </div>
  ) : null;
}

export default Loader;
