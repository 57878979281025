import React from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelUpAlt,
  faLevelDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

import { DefaultColumnFilter } from './Filters';
import 'react-toastify/dist/ReactToastify.css';

import './Table.css';

const HIDDEN_COLS = [
  'location',
  'base',
  'dynamicMovement',
  'moderator',
  'reason',
  'spotifyProfileUrl',
  'instagram',
  'soundCloud',
  'youtube',
];

const Table = ({ columns: userColumns, data }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false,
      autoResetFilters: false,
      columns: userColumns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <ToastContainer />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (HIDDEN_COLS.find((element) => element === column.id)) {
                  column.toggleHidden(true);
                }
                return (
                  <th {...column.getHeaderProps()}>
                    <div
                      className="noselect"
                      {...column.getSortByToggleProps()}
                    >
                      {column.render('Header')}
                      <span className="header-sort">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faLevelDownAlt} />
                          ) : (
                            <FontAwesomeIcon icon={faLevelUpAlt} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {page.length !== 0 && (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {page.length === 0 && <div className="no-results">No results</div>}
      <br />
      <div className="pagination noselect">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span className="page-indicator noselect">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          className="page-size-select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Table;
