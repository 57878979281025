import React from 'react';
import { Step } from 'semantic-ui-react';

const firebase = {
  key: 'firebase',
  icon: 'id card',
  title: 'Login',
  description: 'Enter login information',
};
const mod = {
  key: 'mod',
  icon: 'shield alternate',
  title: 'Account',
  description: 'Enter account information',
};
const verify = {
  key: 'verify',
  icon: 'envelope',
  title: 'Verify',
  description: 'Confirm your email',
};

const RegistrationSteps = ({
  firebaseState = {},
  modState = {},
  verifyState = {},
}) => (
  <Step.Group
    stackable="tablet"
    items={[
      { ...firebase, ...firebaseState },
      { ...mod, ...modState },
      { ...verify, ...verifyState },
    ]}
  />
);

export default RegistrationSteps;
