import './ArtistRequests.css';

import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpotify,
  faInstagram,
  faSoundcloud,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle,
  faTimesCircle,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import {
  faEllipsisH,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

import { Button, Dropdown } from 'semantic-ui-react';

import Table from '../../components/Table/Table';
import InfoModal from './InfoModal';
import { RequestsSelectColumnFilter } from '../../components/Table/Filters';
import Sidebar from '../../components/Sidebar';
import heatwav from '../../apis/heatwav';
import userEvent from '@testing-library/user-event';
import { useRequireAuth } from '../../hooks/useRequireAuth';
import Loader from '../../components/Loader';

function ArtistRequests() {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { user, error: userError } = useRequireAuth({});

  const [token, setToken] = useState(undefined);
  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.getIdToken();
        setToken(token);
      }
    })();
  }, [user, userError]);

  const { data: res, error: swrError, mutate } = useSWR(
    token ? ['/artist-requests', token] : null,
    (url) =>
      heatwav.get(url, {
        headers: {
          authorization: token,
        },
      })
  );

  if (res && res.data && res.data.data && res.data.data.artistRequests) {
    res.data.data.artistRequests.forEach(function (e) {
      e.status = e.status.charAt(0) + e.status.toLowerCase().slice(1);
    });
  }

  const handleReviewArtist = async (id, status, reason) => {
    try {
      const token = await user.getIdToken();
      const response = await heatwav.post(
        `/artist-requests/${id}/review`,
        {
          status: status,
          reason: reason,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        const { artistRequests } = res.data.data;
        mutate(
          artistRequests.map((artistRequest) =>
            artistRequest._id === id
              ? {
                  ...artistRequest,
                  status: status,
                  reason: reason ? reason : artistRequest.reason,
                }
              : artistRequest
          )
        );
      }
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  function openInNewTab(values) {
    window.open(values.instagram, 'one');
    setTimeout(function () {
      window.open(values.soundcloud, 'two');
    }, 100);
    setTimeout(function () {
      window.open(values.spotifyProfileUrl, 'three');
    }, 100);
    setTimeout(function () {
      window.open(values.youtube, 'four');
    }, 100);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        Filter: () => null,
      },
      {
        Header: 'User',
        accessor: 'user',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: (col) => RequestsSelectColumnFilter(col, showInfoModal),
        Cell: ({ row }) => {
          const cellColors = {
            Pending: '#8a8a8a',
            Accepted: '#348037',
            Rejected: '#b35f5f',
          };
          return (
            <div
              style={{
                background: cellColors[row.cells[2].value],
                borderRadius: '10px',
                width: '100px',
                margin: 'auto',
              }}
            >
              {row.cells[2].value}
            </div>
          );
        },
      },
      {
        Header: () => null,
        accessor: 'reason',
        Cell: () => null,
      },
      {
        Header: 'Moderator',
        accessor: 'moderator',
      },
      {
        Header: 'Socials',
        accessor: '',
        id: 'socialMedia',
        Filter: () => null,
        Cell: ({ row }) => {
          return (
            <div>
              <Dropdown text={<FontAwesomeIcon icon={faUser} />}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon={
                      <FontAwesomeIcon
                        className="social-icon"
                        icon={faSpotify}
                      />
                    }
                    target="_blank"
                    description="Spotify"
                    href={row.values.spotifyProfileUrl}
                  />
                  <Dropdown.Item
                    icon={
                      <FontAwesomeIcon
                        className="social-icon"
                        icon={faInstagram}
                      />
                    }
                    target="_blank"
                    description="Instagram"
                    href={row.values.instagram}
                  />
                  <Dropdown.Item
                    icon={
                      <FontAwesomeIcon
                        className="social-icon"
                        icon={faYoutube}
                      />
                    }
                    target="_blank"
                    description="Youtube"
                    href={row.values.youtube}
                  />
                  <Dropdown.Item
                    icon={
                      <FontAwesomeIcon
                        className="social-icon"
                        icon={faSoundcloud}
                      />
                    }
                    target="_blank"
                    description="Soundcloud"
                    href={row.values.soundCloud}
                  />
                  <Dropdown.Item
                    icon={
                      <FontAwesomeIcon
                        className="social-icon"
                        icon={faExternalLinkAlt}
                      />
                    }
                    target="_blank"
                    description="Open All"
                    onClick={() => openInNewTab(row.values)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <Button
            variant="light"
            onClick={() => {
              setShowInfoModal(true);
              setSelectedRow(row);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        ),
      },
      {
        Header: () => null,
        accessor: 'spotifyProfileUrl',
        Cell: () => null,
      },
      {
        Header: () => null,
        accessor: 'instagram',
        Cell: () => null,
      },
      {
        Header: () => null,
        accessor: 'soundCloud',
        Cell: () => null,
      },
      {
        Header: () => null,
        accessor: 'youtube',
        Cell: () => null,
      },
    ],
    [showInfoModal]
  );

  const renderTable = () => {
    return !(res && res.data && res.data.data) ? (
      <div style={{ height: '100vh' }}>
        <Loader
          active={true}
          content="Loading"
          variant="light"
          animation="border"
        />
      </div>
    ) : (
      <>
        <InfoModal
          show={showInfoModal}
          row={selectedRow}
          handleClose={() => setShowInfoModal(false)}
          handleReviewArtist={handleReviewArtist}
        />
        <h2 className="dashboard-header artist-header">
          <span className="artist-requests-num">
            {res.data.data.artistRequests.length}
          </span>{' '}
          ARTISTS REQUESTS
        </h2>
        <Table columns={columns} data={res.data.data.artistRequests} />
      </>
    );
  };

  return (
    <div>
      <Sidebar />

      <div className="dashboard">{renderTable()}</div>
    </div>
  );
}

export default ArtistRequests;
