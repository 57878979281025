import React from 'react';
import moment from 'moment';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from 'react-table';

import Table, {
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
} from './Table';

function ModManagement(props) {
  const data = React.useMemo(
    () => [
      {
        screenName: 'xor321',
        email: 'xor321@gmail.com',
        status: 'ADMIN',
        addedBy: '5efcba7affdf6672f7709e6e',
        createdAt: moment().format(),
      },
      {
        screenName: 'bruh939',
        email: 'bruh939@yahoo.com',
        status: 'ADMIN',
        addedBy: '5efcbaf97fa796381d7ce5c6',
        createdAt: moment().format(),
      },
      {
        screenName: 'hi220',
        email: 'hi220@hotmail.com',
        status: 'MOD',
        addedBy: '5efcbb33f97f9ff94623074d',
        createdAt: moment().format(),
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Screen Name',
        accessor: 'screenName',
        Filter: DefaultColumnFilter,
        filter: 'fuzzy',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: DefaultColumnFilter,
        filter: 'fuzzy',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Added By',
        accessor: 'addedBy',
        Filter: DefaultColumnFilter,
        filter: 'fuzzy',
      },
      {
        Header: 'Joined',
        accessor: 'createdAt',
        Filter: SliderColumnFilter,
        filter: 'between',
      },
    ],
    []
  );
  return (
    <div>
      <h1>Moderator Management</h1>
      <button>New Invite</button>
      <Table columns={columns} data={data} />
    </div>
  );
}

export default ModManagement;
