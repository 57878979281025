import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Loader,
  Segment,
  Message,
  Icon,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from '@reach/router';

import RegistrationSteps from '../../components/RegistrationSteps';

import { useRequireAuth } from '../../hooks/useRequireAuth';
import { useAuth } from '../../hooks/useAuth';

const Verify = (props) => {
  // const auth = useRequireAuth();
  const auth = useAuth();
  const navigate = useNavigate();

  const onResendVerification = async () => {
    if (auth.user) {
      await auth.sendVerificationEmail();
    } else {
      // TODO: throw some kind of error
      console.error('user is not availible');
    }
  };

  // display toast for successful mod registration
  useEffect(() => {
    if (props.location.state && props.location.state.createdFirebaseAccount) {
      toast.success('Saved email and password!');
    }
  }, [props.location.state]);

  const getMessage = () => {
    const reference =
      auth.user && auth.user.email ? auth.user.email : 'your inbox';

    return `Please click the link in the email we sent to ${reference} to finish registration`;
  };

  const handleResend = async () => {
    try {
      if (auth.user) {
        await auth.sendVerificationEmail();
        toast.info('Verification email resent!');
      } else {
        toast.error('You seem to be logged out. Please login and try again.');
      }
    } catch (err) {
      toast.error('Unexpected error. Please try again.');
    }
  };

  const handleDone = async () => {
    if (auth.user) {
      await auth.reloadUser();
      if (auth.user.emailVerified) {
        navigate('/dashboard', {
          state: { verifiedEmail: true },
        });
      } else {
        toast.error('Seems like email has not been verified yet...');
      }
    } else {
      toast.error('You seem to be logged out. Please login and try again.');
    }
  };

  return auth ? (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Container>
            <RegistrationSteps
              firebaseState={{ completed: true }}
              modState={{ completed: true }}
              verifyState={{ active: true }}
            />
            <Message icon>
              <Icon name="mail" />
              <Message.Content>
                <Message.Header>One last thing</Message.Header>
                <p>{getMessage()}</p>
                <Button onClick={handleResend}>
                  Resend verification email
                </Button>
                <Button onClick={handleDone} color="blue">
                  Done
                </Button>
              </Message.Content>
            </Message>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <Loader active="true" content="Loading" />
  );
};

export default Verify;
