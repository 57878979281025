import React from 'react';
import { Modal, Form } from 'react-bootstrap';

import EditArtistModal from './EditArtistModal';

function EditArtistModalWrapper(props) {
  const renderModal = () =>
    props.row ? (
      <EditArtistModal {...props} />
    ) : (
      <Modal.Body>No row selected...</Modal.Body>
    );

  return (
    <>
      <Modal size="lg" show={props.show} onHide={props.handleClose} centered>
        {renderModal()}
      </Modal>
    </>
  );
}

export default EditArtistModalWrapper;
