import { useNavigate } from '@reach/router';
import { useEffect } from 'react';
import { useAuth } from './useAuth';

export const useRequireAuth = ({
  signedOutRedirectUrl = '/login',
  unverifiedRedirectUrl = '/verify',
  unregisteredRedirectUrl = '/register-mod',
  redirectIfSignedIn = false,
  signedInRedirectUrl = '/dashboard',
}) => {
  const auth = useAuth();
  const { user, error, moderator, modError } = auth;
  const navigate = useNavigate();
  useEffect(() => {
    // check if the user has been signed out
    if (user === false) {
      console.log('signing out');
      navigate(signedOutRedirectUrl);
      return;
    }
    // check that the backend server is reachable
    if (error || modError) {
      console.log(error, modError);
      navigate('/error');
      return;
    }
    if (user) {
      if (!user.emailVerified) {
        navigate(unverifiedRedirectUrl);
        return;
      }
      // redirect them to finish registration if they have a firebase account
      // but do not have a mod account
      if (moderator === false) {
        navigate(unregisteredRedirectUrl);
        return;
      }
      if (redirectIfSignedIn) {
        navigate(signedInRedirectUrl);
        return;
      }
    }
  }, [
    auth,
    error,
    modError,
    moderator,
    navigate,
    redirectIfSignedIn,
    signedInRedirectUrl,
    signedOutRedirectUrl,
    unregisteredRedirectUrl,
    unverifiedRedirectUrl,
    user,
  ]);

  return auth;
};
