import React, { useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';
import {
  BsChevronRight,
  BsChevronLeft,
  BsChevronDoubleRight,
  BsChevronDoubleLeft,
} from 'react-icons/bs';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelUpAlt,
  faLevelDownAlt,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { useRequireAuth } from '../../hooks/useRequireAuth';
import { applyPatch } from 'fast-json-patch';

import Table from '../../components/Table/Table';
import { SelectColumnFilter } from '../../components/Table/Filters';
import Sidebar from '../../components/Sidebar';
import EditArtistModal from './EditArtistModal';
import CreateArtistModal from './CreateArtistModal';
import heatwav from '../../apis/heatwav';
import parseLocation from '../../util/parseLocation';
import getArtistType from '../../util/getArtistType';

import './Artists.css';

function Artists() {
  const { user, error: userError, moderator, modError } = useRequireAuth({});
  const [showEditArtist, setShowEditArtist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { data, error: swrError, mutate } = useSWR(['/artists'], (url) =>
    heatwav.get(url)
  );

  const handleCreateArtist = async ({
    source,
    sourceId,
    longitude,
    latitude,
    baseLon,
    baseLat,
    dynamicMovement,
    geojsonFromCoords,
    wander,
  }) => {
    try {
      const token = await user.getIdToken();
      const toastId = toast.info(
        <div>
          Creating artist {`${sourceId}`}...{' '}
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />{' '}
        </div>,
        {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: true,
        }
      );
      const res = await heatwav.post(
        '/artists',
        {
          source,
          sourceId,
          location: geojsonFromCoords(longitude, latitude),
          dynamicMovement,
          base: geojsonFromCoords(baseLon, baseLat),
          wander,
        },
        {
          params: {
            importInfoAndAlbums: 'true',
          },
          headers: {
            authorization: token,
          },
        }
      );
      toast.dismiss(toastId);
      if (res.status === 422) {
        toast.error(
          `Failed to create artist: ${sourceId}. Error: ${res.data.message}`
        );
        return;
      }

      if (res.status !== 201) {
        toast.error(
          `Unknown error creating artist: ${sourceId}, please try again.`
        );
        return;
      }

      const { artists } = data.data.data;
      mutate([...artists, res.data.data.artist]);
      toast.success(`Artist: ${sourceId} successfully created.`, {
        autoClose: 2000,
      });
    } catch (err) {
      console.log(err);
      toast.error(
        `Failed to create artist: ${sourceId}. Error: ${err.message}`
      );
    }
  };

  const handleSyncArtist = async (id, name) => {
    const token = await user.getIdToken();
    const toastId = toast.info(
      <div>
        Syncing artist {name}...{' '}
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />{' '}
      </div>,
      {
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: true,
      }
    );
    const res = await heatwav.put(
      `/artists/${id}/albums/sync`,
      {},
      {
        params: {
          strict: 'true',
        },
        headers: {
          authorization: token,
        },
      }
    );
    toast.dismiss(toastId);
    if (
      res.status === 404 ||
      res.status === 403 ||
      res.status === 400 ||
      res.status === 409
    ) {
      toast.error(
        `Failed to sync artist: ${name}. More info: ${
          res.data ? res.data.nessage : 'unknown...'
        }`
      );
      return;
    }
    if (res.status !== 200) {
      toast.error(
        `Unknown error syncing artist: ${name}, please try again. More info: ${
          res.data ? res.data.nessage : 'unknown...'
        }`
      );
      return;
    }
    toast.success(`Artist: ${name} successfully synced.`, { autoClose: 2000 });
  };

  // handles changing the location of an artist
  const handleEditArtist = async (id, patch) => {
    try {
      const token = await user.getIdToken();
      const res = await heatwav.patch(
        `/artists/${id}`,
        { patch },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status === 200) {
        const { artists } = data.data.data;
        mutate(
          artists.map((artist) =>
            artist._id === id ? applyPatch(artist, patch).newDocument : artist
          )
        );
      }
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // handles deleting an artist
  const handleDeleteArtist = async (id) => {
    try {
      const token = await user.getIdToken();
      const res = await heatwav.delete(`/artists/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        const { artists } = data.data.data;
        mutate(artists.filter((artist) => artist._id !== id));
      }
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // To access row data, you can access the value of each indexed cell
  // E.g. the name value can be accessed using this {{row.cells[0].value}}
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name ',
        accessor: 'name',
      },
      {
        Header: 'Type ',
        accessor: 'type',
        Filter: SelectColumnFilter,
        // Cell: ({ row }) => {
        //   return (
        //     <div>{getArtistType(row.original.users, row.original.simulate)}</div>
        //   );
        // }
      },
      {
        Header: 'Location ',
        accessor: 'area',
        Cell: ({ row }) => {
          const loc = parseLocation(row.values.area, row.values.location);
          return (
            <div style={{ fontSize: '10pt', lineHeight: '25px' }}>{loc}</div>
          );
        },
      },
      {
        Header: () => (
          <span>
            Popularity
            <br />
            Index{' '}
          </span>
        ),
        accessor: 'popularityIndex',
        Filter: () => null,
      },
      {
        Header: () => <span>Wander</span>,
        accessor: 'wander',
        Filter: () => null,
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <Button
            variant="light"
            onClick={() => {
              setShowEditArtist(true);
              setSelectedRow(row);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        ),
      },
      {
        Header: () => null,
        accessor: 'location',
        Cell: () => null,
      },
      {
        Header: () => null,
        accessor: 'base',
        Cell: () => null,
      },
      {
        Header: () => null,
        accessor: 'dynamicMovement',
        Cell: () => null,
      },
    ],
    []
  );

  const renderTable = () => {
    // TODO: make this look better
    if (swrError || userError) {
      return <div>failed to load</div>;
    }

    if (!data) {
      return (
        <div style={{ height: '100vh' }}>
          <Loader
            active={true}
            content="Loading"
            variant="light"
            animation="border"
          />
        </div>
      );
    }
    if (data.data == null || data.data.data == null) return <div>No Data</div>;
    const { count, artists } = data.data.data;

    // calculate the row for whether the artist is active
    const calculatedArtists = artists.map((artist) => ({
      ...artist,
      type: getArtistType(artist.users, artist.simulate),
    }));

    return (
      <>
        <h2 className="dashboard-header artist-header">
          <span className="artist-requests-num">{count}</span> ARTISTS
          <CreateArtistModal handleCreate={handleCreateArtist} />
        </h2>
        <Table columns={columns} data={calculatedArtists} />
      </>
    );
  };

  return (
    <div>
      <Sidebar />
      <EditArtistModal
        show={showEditArtist}
        row={selectedRow}
        handleEdit={handleEditArtist}
        handleClose={() => setShowEditArtist(false)}
        handleSync={handleSyncArtist}
        handleDelete={handleDeleteArtist}
        randomProp="asdfsasdfasdf"
        isAdmin={moderator && moderator.status === 'ADMIN'}
      />
      <div className="dashboard">{renderTable()}</div>
    </div>
  );
}

export default Artists;
