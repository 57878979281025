const url = process.env.REACT_APP_HEATWAV_URL;
const getEnvironment = () => {
  if (!url) {
    return { text: 'unknown', theme: 'secondary' };
  }
  const { hostname } = new URL(url);
  switch (hostname) {
    case 'heatwav-dev.herokuapp.com':
      return { text: 'development', theme: 'danger' };
    case 'heatwav-stage.herokuapp.com':
      return { text: 'staging', theme: 'warning' };
    case 'heatwav-prod.herokuapp.com':
      return { text: 'production', theme: 'sucesss' };
    case 'localhost':
      return { text: 'local', theme: 'info' };
    default:
      return { text: 'unknown', theme: 'secondary' };
  }
};

export default getEnvironment;
