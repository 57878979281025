import React, { useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Col,
  Dropdown,
  FormControl,
  DropdownButton,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faMapMarkerAlt,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../modal.css';

const geojsonFromCoords = (lon, lat) => ({
  type: 'Point',
  coordinates: [lon, lat],
});

const CreateArtistModal = ({ handleCreate }) => {
  const [show, setShow] = useState(false);
  const [source, setSource] = useState('SPOTIFY');
  const [sourceId, setSourceId] = useState('');
  const [longitude, setLongitude] = useState(-73.961601);
  const [latitude, setLatitude] = useState(40.71394);
  const [dynamicMovement, setDynamicMovement] = useState(true);
  const [baseLon, setBaseLon] = useState(-73.961601);
  const [baseLat, setBaseLat] = useState(40.71394);
  const [wander, setWander] = useState(10);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const inputControllers = new Map(
    Object.entries({
      source: (source) => setSource(source.toUpperCase()),
      sourceId: setSourceId,
      longitude: setLongitude,
      latitude: setLatitude,
      dynamicMovement: setDynamicMovement,
      baseLon: setBaseLon,
      baseLat: setBaseLat,
      wander: setWander,
    })
  );

  const handleSubmit = async () => {
    console.log(
      source,
      sourceId,
      longitude,
      latitude,
      dynamicMovement,
      baseLon,
      baseLat,
      wander
    );
    if (!(source && sourceId)) {
      toast.error('Please make sure "source" and "source id" are specified.');
      return;
    }

    handleCreate({
      source,
      sourceId,
      longitude,
      latitude,
      baseLon,
      baseLat,
      dynamicMovement,
      geojsonFromCoords,
      wander,
    });
    handleClose();
  };

  const handleChange = (e) => {
    inputControllers.get(e.target.name)(e.target.value);
  };

  return (
    <>
      <Button variant="light" onClick={handleShow} className="add-artist-btn">
        <FontAwesomeIcon icon={faUserPlus} />
      </Button>
      <Form>
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>
              <span className="edit-artist-header">
                Create Simulated Artist
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <Form.Group controlId="source">
                <Form.Control
                  as="select"
                  name="source"
                  value={source}
                  onChange={handleChange}
                  className="source-select"
                >
                  <option value="Spotify">Spotify</option>
                </Form.Control>
              </Form.Group>
              <FormControl
                aria-describedby="basic-addon1"
                name="sourceId"
                label="Source id"
                placeholder="Spotify ID"
                className="artist-form"
                value={sourceId}
                onChange={handleChange}
              />
            </InputGroup>
            <div className="coord-label">
              Current Coordinates{' '}
              <FontAwesomeIcon className="coord-icon" icon={faMapMarkerAlt} />
            </div>
            <Form.Row className="coord-row">
              <Form.Group as={Col} controlId="baseLon">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Current Longitude"
                  name="longitude"
                  type="number"
                  className="artist-form"
                  value={longitude}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="baseLat">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Current Latitude"
                  name="latitude"
                  type="number"
                  className="artist-form"
                  value={latitude}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
            <div className="coord-label">
              Base Coordinates{' '}
              <FontAwesomeIcon className="coord-icon" icon={faHome} />
            </div>
            <Form.Row className="coord-row">
              <Form.Group as={Col} controlId="baseLon">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Base Longitude"
                  name="baseLon"
                  type="number"
                  className="artist-form"
                  value={baseLon}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="baseLat">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Base Latitude"
                  name="baseLat"
                  type="number"
                  className="artist-form"
                  value={baseLat}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col xs="auto">
                <Form.Group controlId="wander">
                  <Form.Label>Wander</Form.Label>
                  <Form.Control
                    name="wander"
                    type="number"
                    className="artist-form"
                    placeholder="10"
                    value={wander}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  name="dynamicMovement"
                  className="dynamic-movement-checkbox"
                  checked={dynamicMovement}
                  onChange={(e) => setDynamicMovement(e.target.checked)}
                  label="Dynamic Movement"
                />
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="orange"
              type="submit"
              disabled={!source || !sourceId}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};
export default CreateArtistModal;
