import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

function LoadingButton({
  variant,
  block,
  size,
  type,
  children,
  loading,
  disabled,
  onClick,
}) {
  return (
    <Button
      variant={variant}
      block={block}
      size={size}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <Spinner as="span" size="sm" role="status" animation="border" />
      ) : null}
      {children}
    </Button>
  );
}

export default LoadingButton;
