import React, { useState, useEffect } from 'react';
import {
  Alert,
  Modal,
  Button,
  Form,
  Col,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  faSpotify,
  faInstagram,
  faSoundcloud,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import getArtistType from '../../../util/getArtistType';
import LoadingButton from '../../../components/LoadingButton';

import '../../Artists/modal.css';
import './InfoModal.css';

const cellColors = {
  Pending: '#8a8a8a',
  Accepted: '#348037',
  Rejected: '#b35f5f',
};

function InfoModal(props) {
  const { original, values } = props.row;
  async function acceptArtist() {
    // confirm accepting
    if (window.confirm('Are you sure you want to accept artist?')) {
      const toastId = toast.info(
        <div>
          Accepting Artist Request...
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />{' '}
        </div>,
        {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: true,
        }
      );
      const res = await props.handleReviewArtist(
        original._id,
        'ACCEPTED',
        'Test Reason'
      );
      toast.dismiss(toastId);
      if (res.status === 422) {
        toast.error(
          `Failed to accept artist request: . Error: ${res.data.message}`
        );
        return;
      }

      if (res.status !== 201) {
        toast.error(
          `Unknown error accepting artist request: , please try again.`
        );
        return;
      }
    } else {
      return;
    }
    toast.success(`Artist request accepted.`, {
      autoClose: 2000,
    });
  }

  function rejectArtist() {
    // confirm rejecting
    if (window.confirm('Are you sure you want to deny artist?')) {
      props.handleReviewArtist(original._id, 'REJECTED', 'Test Reason');
    } else {
      return;
    }
  }

  const renderModalBody = (status, moderator, reason) => (
    <div>
      <div className="info-modal-content">
        <div className="content-title">Moderator</div>
        <div className="content-content">{moderator}</div>
      </div>
      {status === 'Pending' ? (
        <Form.Group as={Col} controlId="baseLong">
          <Form.Label>
            Reason for Decision <small>(optional)</small>
          </Form.Label>
          <Form.Control className="info-modal-reason" />
        </Form.Group>
      ) : (
        <div className="info-modal-content">
          <div className="content-title">Reason</div>
          <div className="content-content">{reason}</div>
        </div>
      )}
    </div>
  );

  const renderModalFooter = (status) => (
    <div>
      {status === 'Pending' && (
        <div className="accept-deny-btns">
          <Button
            className="info-modal-final-btn"
            onClick={(e) => {
              acceptArtist();
            }}
          >
            Accept
          </Button>
          <Button className="info-modal-final-btn" onClick={rejectArtist}>
            Deny
          </Button>
        </div>
      )}
    </div>
  );
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="info-modal-header">{values.user}</span>
          <span
            style={{ background: cellColors[values.status] }}
            className="info-modal-status"
          >
            {values.status}
          </span>
          <div className="info-modal-btns">
            <ButtonGroup aria-label="social-btns">
              <Button variant="secondary">
                <FontAwesomeIcon className="social-icon" icon={faSpotify} />
              </Button>
              <Button variant="secondary">
                <FontAwesomeIcon className="social-icon" icon={faInstagram} />
              </Button>
              <Button variant="secondary">
                <FontAwesomeIcon className="social-icon" icon={faYoutube} />
              </Button>
              <Button variant="secondary">
                <FontAwesomeIcon className="social-icon" icon={faSoundcloud} />
              </Button>
            </ButtonGroup>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderModalBody(values.status, values.moderator, values.reason)}
      </Modal.Body>
      <Modal.Footer>{renderModalFooter(values.status)}</Modal.Footer>
    </>
  );
}

export default InfoModal;
