import states from 'us-state-codes';

const parseLocation = (area, location) => {
  if (!area || !location) {
    return 'N/A';
  }

  const { features, proximate } = area;

  if (proximate === 'Unknown') {
    return location.coordinates.join(', ');
  }

  const list = [
    features.neighborhood,
    features.locality,
    features.place,
    features.region
      ? features.country === 'United States'
        ? states.getStateCodeByStateName(features.region)
        : features.region
      : '',
    features.country,
  ];

  const loc = list.filter((e) => e).join(', ');

  return loc === '' ? proximate : loc;
};

export default parseLocation;
