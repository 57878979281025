import React, { Component } from 'react';

import './ArtistCard.css';

export default function ArtistCard(props) {
  console.log(props.data);

  const IGLogo =
    'https://cdn.iconscout.com/icon/free/png-256/instagram-188-498425.png';
  const SpotifyLogo =
    'https://developer.spotify.com/assets/branding-guidelines/icon4@2x.png';
  const TwitterLogo =
    'https://images.vexels.com/media/users/3/137419/isolated/preview/b1a3fab214230557053ed1c4bf17b46c-twitter-icon-logo-by-vexels.png';

  const socialsLinks = (
    <a href={props.data.link_to_spotify}>
      <div className="social-icon-wrapper">
        <img className="social-icon" src={SpotifyLogo} alt="spotify-logo" />
      </div>
    </a>
  );

  const popStyle = !props.data
    ? null
    : { transform: `scale(${0.66 + props.data.popularity / 133})` };

  return (
    <div className="artist-card">
      <div className="artist-popularity-container" style={popStyle}>
        <i className="fa fa-caret-up" aria-hidden="true" />
        <h1 className="level-label">Radius</h1>
        <h1>{!props.data ? null : props.data.radius}</h1>
      </div>
      <div className="artist-card-header">
        <div className="artist-img-wrapper">
          <img
            src={!props.data ? null : props.data.profile_img}
            alt={
              !props.data ? null : props.artist_name + ' spotify profile img'
            }
          />
        </div>
        <div className="artist-locale-container">
          <h1>{!props.data ? null : props.data.neighborhood}</h1>
        </div>
        <h1 className="artist-name">
          {!props.data ? null : props.data.artist_name}
        </h1>
        <div className="socials-col">{socialsLinks}</div>
      </div>
    </div>
  );
}
