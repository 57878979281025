import React, { Component } from 'react';
import ArtistCard from '../ArtistCard/ArtistCard';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import MapboxConfig from '../../config/mapbox';
import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css';
import _mapSettings from './_mapSettings';
import heatwav from '../../apis/heatwav';

import './Map.css';
import AuthContext from '../../contexts/AuthContext';

//import logo from '../../assets/heatwav.png';

mapboxgl.accessToken = MapboxConfig.MAPBOX_ACCESS_TOKEN;

export default class Map extends Component {
  static contextType = AuthContext;

  state = {
    world: 'Earth',
    lng: -73.9866,
    lat: 40.6942,
    zoom: 13.33,
    map: null,
    data: null,
  };

  componentDidMount() {
    let marker;
    let timer;

    const addPopup = (el, coordinates) => {
      const placeholder = document.createElement('div');
      ReactDOM.render(el, placeholder);

      marker = new mapboxgl.Popup({
        closeOnClick: true,
        anchor: 'bottom-left',
      })
        .setDOMContent(placeholder)
        .setLngLat(coordinates)
        .addTo(this.map);
    };

    heatwav
      .get('/artists')
      .then((res) => {
        const heatwavArtists = res.data.data.artists;
        const geoData = [];
        heatwavArtists
          .filter((a) => a.location && a.location.coordinates)
          .map(async (artist) => {
            const neighborhood = !artist.area
              ? 'Unknown'
              : artist.area.features.neighborhood;

            geoData.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: artist.location.coordinates,
              },
              properties: {
                popularity: artist.popularityIndex,
                radius: artist.radius,
                artist_name: artist.name,
                profile_img:
                  artist.art.length === 0
                    ? 'https://www.reseau-maladies-rares.fr/uploads/intervenants/intervenant-sansimage_thumb.jpg'
                    : artist.art[0].url,
                neighborhood: neighborhood,
                link_to_spotify: artist.link,
              },
            });
          });
        return geoData;
      })
      .then((geoData) => {
        const data = {
          type: 'FeatureCollection',
          features: geoData,
        };

        this.setArtists(data);
        console.log(data);

        this.map = new mapboxgl.Map({
          container: this.mapContainer,
          style: 'mapbox://styles/mapbox/dark-v10',
          center: [this.state.lng, this.state.lat],
          zoom: this.state.zoom,
        });
        const map = this.map;
        _mapSettings(map);
        map.on('style.load', (e) => {
          map.addSource('artists', {
            type: 'geojson',
            data: data,
          });
        });
        map.on('mouseenter', 'artists-point', (e) => {
          if (marker) {
            marker.remove();
          }
          clearTimeout(timer);
          const coordinates = e.features[0].geometry.coordinates.slice();
          const artistData = e.features[0].properties;
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          addPopup(
            <ArtistCard data={artistData} auth={this.props.auth} />,
            coordinates
          );
        });
        map.on('mouseleave', 'artists-point', function (e) {
          if (marker) {
            timer = setTimeout(() => {
              marker.remove();
            }, 2900);
          }
        });
        map.on('move', () => {
          this.setState({
            ...this.state,
            lng: map.getCenter().lng.toFixed(4),
            lat: map.getCenter().lat.toFixed(4),
            zoom: map.getZoom().toFixed(2),
          });
        });
        this.setState({
          ...this.state,
          map: map,
        });
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          error: e,
        });
      });
    /*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setUserPosition);
    } else {
      console.log('Geolocation not supported in this browser');
    }*/
  }

  setArtists = (geoData) => {
    this.setState({
      ...this.state,
      data: geoData,
    });
  };
  setUserPosition = (position) => {
    this.setState({
      ...this.state,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };
  render() {
    console.log(this.context);
    const greeting = this.context.moderator ? (
      <span className="greeting-text">
        Welcome, {this.context.moderator.screenName}
      </span>
    ) : null;
    const DATE_OPTIONS = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return (
      <>
        <div className="map-info">
          <h1 className="greeting">HEAT.WAV | {greeting}</h1>
          <p className="date">
            {new Date().toLocaleDateString('en-US', DATE_OPTIONS)}
          </p>
          <div className="map-params">
            <p>LAT: {this.state.lat}</p>
            <p>LNG: {this.state.lng}</p>
            <p>ZOOM: {this.state.zoom}</p>
          </div>
        </div>
        <div ref={(el) => (this.mapContainer = el)} className="mapContainer" />
      </>
    );
  }
}
