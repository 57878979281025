import React from 'react';
import { Router } from '@reach/router';
import { ToastContainer } from 'react-toastify';

import Login from '../../views/Login';
import FirebaseRegistration from '../../views/FirebaseRegistration';
import ModRegistration from '../../views/ModRegistration';
import Dashboard from '../../views/Dashboard';
import ArtistRequests from '../../views/ArtistRequests';
import Artists from '../../views/Artists';
import ModManagement from '../../views/ModManagement';
import ModInvites from '../../views/ModInvites';
import Verify from '../../views/Verify';
import NotFound from '../../views/NotFound';
import Error from '../../views/Error';
import { ProvideAuth } from '../../hooks/useAuth';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ToastContainer />
        <Router>
          <NotFound default />
          <NotFound path="404" />
          <Error path="error" />
          <Login path="login" />
          <Dashboard path="/" />
          <Dashboard path="dashboard" />
          <FirebaseRegistration path="register-firebase" />
          <ModRegistration path="register-mod" />
          <ArtistRequests path="requests" />
          <Artists path="artists" />
          <Verify path="verify" />
          <ModManagement path="moderator" />
          <ModInvites path="invites" />
        </Router>
      </ProvideAuth>
    </div>
  );
}

export default App;
