import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '../config/firebase.js';
import AuthContext from '../contexts/AuthContext';
import heatwav from '../apis/heatwav';

firebase.initializeApp(firebaseConfig);

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  // note a user value of: false = signed out, null = loading
  const [user, setUser] = useState(null);
  const [moderator, setModerator] = useState(null);
  const [error, setError] = useState(null);
  const [modError, setModError] = useState(null);

  const signin = async (email, password) => {
    try {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      setUser(user);
      setError(null);
      return user;
    } catch (err) {
      await setError(err);
      return false;
    }
  };

  const signup = async (email, password) => {
    try {
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      setUser(user);
      // send verification email if the user's email is not verified
      if (!user.emailVerified) {
        await user.sendEmailVerification();
      }
      setError(null);
      return user;
    } catch (err) {
      setError(err);
      return false;
    }
  };

  const signout = async () => {
    try {
      await firebase.auth().signOut();
      setUser(false);
      setError(null);
      return true;
    } catch (err) {
      setError(err);
      return false;
    }
  };

  // make sure the user is loaded before callling this
  const sendVerificationEmail = async () => {
    try {
      await user.sendEmailVerification();
      setError(null);
      return true;
    } catch (err) {
      setError(err);
      return false;
    }
  };

  // make sure the user is loaded before calling this
  const getIdToken = useCallback(async () => {
    try {
      const token = await user.getIdToken();
      setError(null);
      return token;
    } catch (err) {
      setError(err);
      return null;
    }
  }, [user]);

  const sendPasswordResetEmail = async (email) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      return true;
    } catch (err) {
      setError(err);
      return false;
    }
  };

  const confirmPasswordReset = async (code, password) => {
    try {
      await firebase.auth().confirmPasswordReset(code, password);
      setError(null);
      return true;
    } catch (err) {
      setError(err.errCode);
      return false;
    }
  };

  const reloadUser = async () => {
    if (user) {
      try {
        await firebase.auth().currentUser.reload();
        setError(null);
        return true;
      } catch (err) {
        setError(err);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    (async () => {
      // make sure that the user has already been loaded
      if (user !== null) {
        try {
          const token = await getIdToken();
          const res = await heatwav.get('/moderators/me', {
            headers: { authorization: token },
          });
          if (res.status !== 200) {
            setModerator(false);
            return;
          }
          const { moderator } = res.data.data;
          if (moderator) {
            console.log('fetched mod', moderator);
            setModerator(moderator);
          } else {
            console.log('failed to fetch mod');
            setModerator(false);
          }
        } catch (err) {
          setModError(err);
          setModerator(false);
        }
      }
    })();
  }, [getIdToken, user]);

  return {
    user,
    error,
    moderator,
    modError,
    signin,
    signup,
    signout,
    sendVerificationEmail,
    getIdToken,
    sendPasswordResetEmail,
    confirmPasswordReset,
    reloadUser,
  };
};
